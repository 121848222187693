.Payment {
    padding: 80px 0px;
    width: 84%
}

.site-header h6 {
    color: #080526;
    background: yellow;
    text-align: center;
    padding: 20px;
    font-size: 24px;
    font-weight: bold;
}

.btn-primary {
    margin: 10px auto;
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 12px;
    border-radius: 12px;
    background-color: #1711682b;
}
