   /* ===== HOME Section START from here ===== */

   .before-login {
       box-sizing: border-box;
       text-decoration: none;
       moz-transition: all .4s ease-in-out;
       -o-transition: all .4s ease-in-out;
       -webkit-transition: all .4s ease-in-out;
       transition: all .4s ease-in-out;
       /* Set an appropriate height */
       margin: 0px !important;
       padding: 0px !important;
   }

   body {
       font-family: 'Poppins', sans-serif;
       color: #ffffff;
       background-color: #0A0825 !important;
       box-sizing: border-box;
   }

   * {
       box-sizing: border-box;
       text-decoration: none;
       moz-transition: all .4s ease-in-out;
       -o-transition: all .4s ease-in-out;
       -webkit-transition: all .4s ease-in-out;
       transition: all .4s ease-in-out;
   }

   #home {
       position: relative;
       overflow: hidden;
       width: 100%;
       height: 700px;
       background: url("assets/images/BG-HERO..webp")no-repeat;
       background-size: 1440px!important;
   }

   .hero-text {
       margin-right: auto;
       margin-left: auto;
       text-align: left;
   }

   .hero-content {
       margin: 140px 0px;
   }

   .hero-text h1 {
       font-size: 46px;
       font-weight: bolder;
       width: 90%;
       color: #ffffff;
       margin-right: auto;
       margin-left: auto;
       padding: 10px 0px;
   }

   .hero-text-backup-H1 {
       font-size: 52px;
       font-weight: bolder;
       width: 90%;
       margin-right: auto;
       margin-left: auto;
       padding: 10px 0px;
       text-transform: uppercase;
       background-image: url('https://i.ibb.co/VQhBB1H/Main-00000.png');
       -webkit-background-clip: text;
       background-clip: text;
       background-size: 150% 100%;
       -webkit-text-fill-color: transparent;
       animation: gradient-animation 16s ease-in-out infinite;
       text-align: center;
   }

   @keyframes gradient-animation {
       0% {
           background-position: 0% 50%;
       }

       50% {
           background-position: 100% 50%;
       }

       100% {
           background-position: 0% 50%;
       }
   }

   .hero-text p {
       color: #f1f1f19b;
       width: 90%;
       font-weight: 360;
       margin: 15px auto;
   }

   video {
       width: 80%;
       max-width: 900px;
       display: block;
       margin: 0 auto;
       border-radius: 24px;
   }

   /* Hide default video controls */
   video::-webkit-media-controls {
       display: none;
   }

   video::-webkit-media-controls-enclosure {
       display: none !important;
   }

   video::-webkit-media-controls-panel {
       display: none !important;
   }

   .hero-button {
       margin: 20px auto;
       justify-content: center;
       align-items: center;
       text-align: center;
       display: none;
   }

   .hero-button-dekstop {
       margin: 30px;

   }

   /* ===== Navbar Section START from here ===== */
   /*Navbar Start */

   .footer-nav {
       width: 100% !important;
       z-index: 10;
       position: relative;
       margin-right: auto;
       margin-left: auto;
       margin: 0px auto;
       justify-content: center !important;
       padding: auto;
   }

   .navbar-brand {
       font-weight: bold;
   }

   .navbar-brand a {
       font-size: 21px;
       font-weight: 580;
       color: #f1f1f1;
       text-decoration: none;
       text-shadow: 4px 2px 20px 0px #201E2040;
   }

   .favicon-logo {
       max-width: 42px;
   }

   .navbar-brand a span {
       transition: all 0.3s ease;
   }

   .navbar-nav {
       margin: 0px auto;
   }

   .nav-item {
       justify-content: space-around;
       color: #f1f1f1;
       margin: 0px 8px;
   }

   .nav-item a {
       font-size: 17px;
       font-weight: lighter;
       opacity: 0.7;
   }

   .nav-item i {
       font-size: 14px;
   }

   .navbar-toggler {
       border: transparent;
       color: transparent;
   }

   .bg-body-tertiary {
       background-color: transparent !important;
   }

   .cs-dark .cs-white_bg,
   .cs-dark .cs-nav .cs-nav_list ul {
       background-color: #283645;
   }

   .navbar {
       line-height: 2rem !important;
   }

   .navbar .navbar-brand {
       color: #FFF !important;
   }

   .navbar-nav .nav-item .nav-link {
       color: #ffffff;
   }

   .dropdown-menu.show {
       display: none;
   }

   .dropdown-toggle::after {
       display: none;
       margin: 0px;
   }

   .fa-angle-down {
       display: inline-block;
       position: relative;
       float: right;
       transform: rotate(0);
   }

   .nav-link[aria-expanded=true] .fa-angle-down {
       transform: rotate(180deg);
       transition: 0.25s all ease-in-out;
   }

   .nav-link {
       font-size: 21px;
       color: #ffffff;
   }

   .nav-link:hover {
       color: #ffffff;
   }

   /*navbar buttons*/

   .footer-buttons {
       align-items: center;
       display: flex;
       text-transform: uppercase;
       float: right;
   }

   .footer-buttons i {
       margin: 2px 12px;
       font-size: 16px;
       float: right;
   }

   #nav-icon {
       width: 35px;
       height: 35px;
       position: relative;
       margin: 0px auto !important;
       -webkit-transform: rotate(0deg);
       -moz-transform: rotate(0deg);
       -o-transform: rotate(0deg);
       transform: rotate(0deg);
       -webkit-transition: .5s ease-in-out;
       -moz-transition: .5s ease-in-out;
       -o-transition: .5s ease-in-out;
       transition: .5s ease-in-out;
       cursor: pointer;
       /*   border:1px solid red; */
   }

   #nav-icon span {
       display: block;
       position: absolute;
       height: 3px;
       width: 100%;
       background: #ffffff;
       border-radius: 9px;
       opacity: 1;
       right: 0;
       -webkit-transform: rotate(0deg);
       -moz-transform: rotate(0deg);
       -o-transform: rotate(0deg);
       transform: rotate(0deg);
       -webkit-transition: .25s ease-in-out;
       -moz-transition: .25s ease-in-out;
       -o-transition: .25s ease-in-out;
       transition: .25s ease-in-out;
       /*   border:4px solid black; */
   }

   #nav-icon span:nth-child(1) {
       top: 0px;
       -webkit-transform-origin: left center;
       -moz-transform-origin: left center;
       -o-transform-origin: left center;
       transform-origin: left center;
       width: 70%;
   }

   #nav-icon span:nth-child(2) {
       top: 10px;
       -webkit-transform-origin: left center;
       -moz-transform-origin: left center;
       -o-transform-origin: left center;
       transform-origin: left center;
   }

   #nav-icon span:nth-child(3) {
       top: 20px;
       -webkit-transform-origin: left center;
       -moz-transform-origin: left center;
       -o-transform-origin: left center;
       transform-origin: left center;
       width: 70%;
   }

   #nav-icon.open span:nth-child(1) {
       -webkit-transform: rotate(45deg);
       -moz-transform: rotate(45deg);
       -o-transform: rotate(45deg);
       transform: rotate(45deg);
       top: -3px;
       left: 2px;
       width: 100%;
   }

   #nav-icon.open span:nth-child(2) {
       width: 0%;
       opacity: 0;
   }

   #nav-icon.open span:nth-child(3) {
       -webkit-transform: rotate(-45deg);
       -moz-transform: rotate(-45deg);
       -o-transform: rotate(-45deg);
       transform: rotate(-45deg);
       top: 22px;
       left: 2px;
       width: 100%;
   }

   /* ===== Navbar Section END from here ===== */

   /* ===== BUTTONS Start from here ===== */

   .btn {
       text-align: center;
       display: flex;
       cursor: pointer;
       font-size: 14px;
       justify-content: center;
       align-items: center;
       text-transform: uppercase;
       font-weight: 600;
   }

   .btn-hover {
       font-size: 16px;
       font-weight: normal;
       color: #fff;
       padding: 15px 30px;
       cursor: pointer;
       text-align: center;
       border: none;
       background-size: 300% 100%;
       border-radius: 9px;
       moz-transition: all .4s ease-in-out;
       -o-transition: all .4s ease-in-out;
       -webkit-transition: all .4s ease-in-out;
       transition: all .4s ease-in-out;
       backdrop-filter: blur(6px);
   }

   .hero-btn-color {
       background-size: 300% 100%;
       border-radius: 9px;
       background-image: linear-gradient(to right, #0ba360, #3cba92, #30dd8a, #2bb673);
       moz-transition: all .4s ease-in-out;
       -o-transition: all .4s ease-in-out;
       -webkit-transition: all .4s ease-in-out;
       transition: all .4s ease-in-out;
   }

   .btn-Pro {
       background-size: 300% 100%;
       border-radius: 9px;
       background-image: linear-gradient(to right, #ff0000, #ff0092, #ff008a, #ff0073);
       moz-transition: all .4s ease-in-out;
       -o-transition: all .4s ease-in-out;
       -webkit-transition: all .4s ease-in-out;
       transition: all .4s ease-in-out;
   }

   .btn-hover i {
       margin: 0px 10px;
       font-weight: bold;
   }

   .btn-hover:hover {
       background-position: 100% 0;
       moz-transition: all .4s ease-in-out;
       -o-transition: all .4s ease-in-out;
       -webkit-transition: all .4s ease-in-out;
       transition: all .4s ease-in-out;
   }

   .btn-hover:focus {
       outline: none;
   }

   .btn-hover.color-4 {
       background-image: linear-gradient(to right, #0ba360, #3cba92, #30dd8a, #2bb673);
       box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75);
   }

   .btn-hover.nav-btn {
       background-image: linear-gradient(to right, #f1f1f1, #f1f1f18b, #f1f1f1, #fff);
       border: 2px solid #0000002b;
       color: #f1f1f1d4;
       box-shadow: none;
   }

   /* ===== BUTTONS END from here ===== */


   /* ===== About Us Section Start from here ===== */

   #About-us {
       height: 660px;
       padding: 130px 0px;
   }

   .About-us-mob-img {
       display: none;
   }

   .About-us-mob-text {
       display: none;

   }

   .About-us-right-img {
       position: relative;
       margin-left: auto;
       margin-right: auto;
       max-width: 880px;
   }

   .About-us-right-img img {
       width: 100%;
       height: 100%;
       position: relative;
       display: block;
       margin-left: auto;
       margin-right: auto;
   }

   .About-us-left {
       position: relative;
       margin: -20px 0px;
   }

   .About-us-left .title {
       font-size: 46px;
       font-weight: bolder;
       color: #f1f1f1;
       margin: 25px 0px;
   }

   .About-us-left .des {
       font-size: 17px;
       color: #f1f1f19b;
       font-weight: 310;
   }

   .About-hero-btn {
       margin: 25px 0px;
       display: flex;
   }

   /* ===== About Us Section End from here ===== */

   .About-us-right-img2 {
       position: relative;
       margin-left: auto;
       margin-right: auto;
       max-width: 880px;
   }

   .About-us-right-img2 img {
       width: 100%;
       height: 100%;
       position: relative;
       display: block;
       margin-left: auto;
       margin-right: auto;
   }

   /* ===== Section Header START from here ===== */

   .section-header {
       position: relative;
       font-weight: bold;
       width: 80%;
       margin-right: auto;
       margin-left: auto;
   }

   .section-header h2 {
       font-size: 38px;
       font-weight: bold;
       margin: 15px 0px;
       color: #f1f1f1;
   }

   .section-header s {
       max-inline-size: 600px;
       margin: auto;
       font-weight: 310;
       opacity: 0.8;
       text-transform: capitalize;
       font-size: 18px;
   }

   .hei-180 {
       height: 66px;
   }

   /* ===== About Us Section END from here ===== */

   /* ===== How it works Section Start from here ===== */

   #How-it-works {
       position: relative;
       height: 455px;
       padding: 35px 0px !important;
       background: url("assets/images/BG-how-it-wprks..webp");
       background-size: cover;
   }

   .steps-col {
       position: relative;
       background: transparent;
       border-radius: 18px;
       margin: 20px;
       text-align: center;
   }

   .steps-col .row-number {
       max-width: 260px;
       margin-right: auto;
       margin-left: auto;
       margin: 20px auto;
   }

   .steps-col .row-number img {
       width: 100%;
       height: 100%;
   }

   .steps-col .row-number .box {
       background-color: #e9e9e909;
       max-width: 280px;
       border-radius: 8px;
       height: 180px;
       position: relative;
       display: block;
   }

   .steps-col .row-number .box-transparent {
       background-color: transparent;
       max-width: 280px;
       border-radius: 8px;
       height: 180px;
       position: relative;
       display: block;
   }

   /* LOADER 4 */

   #col-loader span {
       display: inline-block;
       width: 8px;
       height: 8px;
       border-radius: 100%;
       background-color: #ffffff;
       margin: 32% 5px;
       opacity: 0;
   }

   #col-loader span:nth-child(1) {
       animation: opacitychange 1s ease-in-out infinite;
   }

   #col-loader span:nth-child(2) {
       animation: opacitychange 1s ease-in-out 0.33s infinite;
   }

   #col-loader span:nth-child(3) {
       animation: opacitychange 1s ease-in-out 0.66s infinite;
   }

   @keyframes opacitychange {

       0%,
       100% {
           opacity: 0;
       }

       80% {
           opacity: 1;
       }
   }

   .steps-title {
       margin: 8px 0px;
   }

   .steps-content {
       display: inline;
       align-items: center;
       gap: 10px;
       margin: 5px 0px;
   }

   .steps-title span {
       font-size: 20px;
       font-weight: bold;
   }

   .steps-content s {
       color: #f1f1f19b;
       font-size: 14px;
       font-weight: lighter;
   }

   /* ===== How it works Section END from here ===== */

   /* ===== About us2 start from here ===== */

   #About-us2 {
       height: 480px;
       padding: 90px 0px;
   }

   /* ===== About us2 end from here ===== */

   /*===== FAQ QNA Section START from here =====*/

   #faq {
       padding: 40px 0;
   }

   .faq-container {
       text-align: center;
       padding: 0 20px;
       min-height: 160px;
   }

   .faq-content {
       margin: 20px 0;
       width: 100%;
   }

   .faq-container h2 {
       font-size: 36px;
       margin: 20px 0;
   }

   .faq-container p {
       color: #ffffffcd;
       font-size: 18px;
       margin: 10px 0;
       font-weight: 320;
   }

   .faq-container a {
       font-size: 18px;
       margin: 10px 5px;
       font-weight: 400;
       color: #2BAE66;
       opacity: 1;
   }

   .accordion {
       margin: 30px 0;
   }

   .accordion .accordion-item {
       padding: 0 20px;
       width: 100%;
       border-radius: 12px;
       margin: 5px 0;
       background-color: transparent;
       border: 0px;
   }

   .accordion button {
       display: block;
       width: 100%;
       padding: 1em 0;
       color: #ffffff;
       font-size: 1.2rem;
       font-weight: bold;
       outline: none;
       background: transparent;
       border: transparent;
       white-space: nowrap;
   }

   .accordion button:hover,
   .accordion button:focus {
       cursor: pointer;
   }

   .accordion button:hover::after,
   .accordion button:focus::after {
       cursor: pointer;
       color: #201E20;
   }

   .accordion button .accordion-title {
       padding: 1em 1.5em 1em 0;
   }

   .accordion button[aria-expanded=true] {
       color: #ffffff;
   }

   .accordion button[aria-expanded=true] .fa-angle-down {
       transform: rotate(180deg);
       transition: 0.25s all ease-in-out;
   }

   .accordion button[aria-expanded=true]+.accordion-content {
       opacity: 1;
       max-height: 9em;
       transition: all 200ms linear;
       will-change: opacity, max-height;
   }

   .accordion .accordion-content {
       opacity: 0;
       max-height: 0;
       overflow: hidden;
       transition: opacity 200ms linear, max-height 200ms linear;
       will-change: opacity, max-height;
       padding: 0 10px;
       width: 100%;
   }

   .accordion .accordion-content p {
       font-size: 1rem;
       font-weight: 300;
       color: #ffffff;
       opacity: 0.8;
   }

   .Faq-right {
       position: relative;
   }

   .Faq-right-img {
       position: relative;
       margin: 20px auto;
       max-width: 520px;
       backdrop-filter: blur(20px);
       overflow: hidden;
   }

   .Faq-right-img img {
       width: 100%;
       height: 100%;
       display: block;
       object-fit: cover;
   }

   /*===== FAQ QNA Section End from here =====*/

   /*===== Site footer START Start from here ===== */

   .site-footer {
       padding: 45px 0 20px;
       font-size: 15px;
       line-height: 24px;
       background-color: #f1f1f107;
       color: #2BAE66;
   }

   .site-footer p {
       font-size: 12px;
   }

   .copyright-text {
       text-align: center;
   }

   /* social icons */

   .social-icons {
       padding-left: 0;
       list-style: none;
   }

   /*===== Site footer END Start from here ===== */