.login {
    width: 400px;
    background: transparent;
    border: none;
    border-radius: 8px;
    padding: 1rem;
    align-items: center;
    text-align: center;
}

.login>h1 {
    font-size: 28px;
    font-weight: bolder;
    width: 60%;
    color: #ffffff;
    margin-right: auto;
    margin-left: auto;
    padding: 10px 0px;
}

@media (max-width: 991.98px) {
    .login>h1 {
        font-size: 30px;
        width: 99%;
        margin-right: auto;
        margin-left: auto;
    }
}

.login>.input {
    border-radius: 8px;
    background-color: #f1f1f11a;
    outline: none;
    color: #ffffff;
    margin: 0.5rem 0;
    padding: 0.5rem 0.75rem;
    width: 92%;
    font-size: 1rem;
    margin: 15px auto;
}


.login>.input>input {
    width: 90%;
    padding: 4px 10px;
}

.input .login-input {}


.transparent-input {
    background-color: transparent;
    border: 1px solid transparent;
    color: #fff;
    font-weight: lighter;
}

.transparent-input:-webkit-autofill,
.transparent-input:-webkit-autofill:hover,
.transparent-input:-webkit-autofill:focus {
    background-color: transparent !important;
    border: 1px solid transparent !important;
    -webkit-text-fill-color: #fff !important;
    /* Set the text color */
    transition: background-color 5000s ease-in-out 0s;
    /* Adjust the transition duration as needed */
}

.color-2 {
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0.5rem;
    margin: 0.5rem 0;
    cursor: pointer;
    border: none;
    font-weight: 590;
    background-size: 300% 100%;
    border-radius: 9px;
    background-image: linear-gradient(to right, #0ba360, #3cba92, #30dd8a, #2bb673);
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.color-2:hover {
    background-position: 100% 0;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.color-1:focus {
    outline: none;
}

.transparent-btn {
    cursor: pointer;
    margin-top: 50px;
    padding: 15px 0.5rem;
    font-weight: 590;
    border: 1px solid #f1f1f11a;
    border-radius: 9px;
}

.middle-text {
    opacity: 0.6;
}