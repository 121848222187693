.text-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    opacity: 0;
    /* Initially hide the text */
    animation: fadeIn 2s forwards, writeText 4s steps(40) forwards;
    /* Combine fadeIn with writeText */
}

.writer-text-hero {
    width: 90%;
    height: 60px;
    margin: 283px auto;
    background-color: #0A0825;
    border-radius: 12px;
    border: 2px solid #30dd8a;
    padding: 5px 0px;
    opacity: 0.8 !important;
    backdrop-filter: blur(1px);
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes writeText {
    0% {
        width: 0;
        /* Start with no width (hidden) */
    }

    100% {
        width: 100%;
        /* Increase width to reveal text */
    }
}

.cards {
    max-width: 480px;
    margin: 0 auto;
    perspective: 1000px;
}

.cards__container {
    width: 100%;
    position: relative;
    padding-bottom: 71.5%;
    transition: 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform-style: preserve-3d;
}

.cards__front,
.cards__back {
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 12px;
    background-size: cover;
}

.cards__front {
    z-index: 2;
    transform: rotateY(0deg);
}

.cards__back {
    transform: rotateY(180deg);
}

.cards.is-flipped .cards__container {
    transform: rotateY(180deg);
}

.card-1 {
    background-image: url('hero-slide-img..webp');
}

.card-2 {
    background-image: url('hero-slide-img-2..webp');
}

.card-3 {
    background-image: url('hero-slide-img-3.webp');
}

.card-4 {
    background-image: url('hero-slide-img-4.webp');
}