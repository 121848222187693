.register {
    width: 400px;
    background: transparent;
    border: none;
    border-radius: 8px;
    padding: 1rem;
    align-items: center;
    text-align: center;
}

.register>input {
    border-radius: 8px;
    background-color: #f1f1f11a;
    outline: none;
    color: #ffffff;
    margin: 0.5rem 0;
    padding: 0.5rem 0.75rem;
    width: 92%;
    font-size: 1rem;
    margin: 15px auto;
}

.register>h1 {
    font-size: 28px;
    font-weight: bolder;
    width: 60%;
    color: #ffffff;
    margin-right: auto;
    margin-left: auto;
    padding: 10px 0px;
}

@media (max-width: 991.98px) {
    .register>h1 {
        font-size: 30px;
        width: 99%;
        margin-right: auto;
        margin-left: auto;
    }
}

.register>.input {
    border-radius: 8px;
    background-color: #f1f1f11a;
    outline: none;
    color: #ffffff;
    margin: 0.5rem 0;
    padding: 0.5rem 0.75rem;
    width: 92%;
    font-size: 1rem;
    margin: 15px auto;
}


.register>.input>input {
    width: 90%;
    padding: 4px 10px;
}

.input .register-input {}