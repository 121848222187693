/* About.css */
.info-icon {
    display: none;
    /* Hide the icon by default */
}

.not-visible {
    display: block;
}

.nav-visible {
    display: none;
}

.nav-content {
    display: none;
}

@media (max-width: 991.98px) {

    /* ===== Navbar Section START from here ===== */

    .footer-nav {
        width: 100% !important;
        z-index: 100 !important;
    }

    .info-icon {
        display: block;
        float: right;
        margin: 7px;
    }

    .nav-visible {
        display: block;
    }

    .not-visible {
        display: none;
    }

    .nav-pro {
        font-size: 21px !important;
        font-weight: 550 !important;
    }

    .navbar-nav {
        margin: 0;
        border-bottom: 1px solid #f1f1f11b;
    }

    .navbar {
        margin: 10px auto !important;
    }

    .navbar-collapse {
        position: absolute;
        top: 99%;
        right: 0;
        left: 0;
        margin-top: 0px !important;
        transition: .2s;
        background-color: #08061F9b !important;
        border-radius: 0px;
        padding: 15px;
        z-index: 1000;
        overflow: hidden;
        overflow-y: hidden;
        min-height: 100vh;
        max-height: 150vh;
        width: 100%;
        backdrop-filter: blur(4px);
    }

    .navbar-brand {
        margin: 5px 10px !important;
    }

    .nav-item {
        color: #000;
    }

    .nav-item a {
        margin: 0px 6px;
    }

    #nav-icon {
        margin: 0;
    }

    .dropdown-menu.show {
        position: relative;
        display: block;
        background-color: transparent;
        color: #000;
        margin: 0px;
        border: transparent;
        transition: opacity 200ms linear, max-height 200ms linear;
    }

    .dropdown-toggle .icon {
        float: right;
        padding: 8px;
        display: flex;
    }

    .dropdown-menu li a {
        color: #000;
        border-top: 1px solid rgba(255, 255, 255, 0.041);
    }

    .dropdown-menu li a:hover {
        background-color: transparent;
        color: #000;
    }

    .footer-buttons {
        column-gap: 1px;
        display: flex;
        margin: 0px;
    }

    .footer-buttons button {
        margin: 8px auto !important;
        font-size: 16px;
    }

    .footer-buttons i {
        font-size: 16px;
        margin: 2px 8px;
    }

    .nav-content {
        display: block;
        min-height: 60px;
        max-height: 60px;
        border-bottom: 1px solid #f1f1f11b;
    }

    .user-icon {
        font-size: 26px !important;
        margin: 0px 5px;
    }

    /* ===== Navbar Section END from here ===== */

    /* ===== Home Section Start from here ===== */

    #home {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 775px;
        background: url("assets/images/BG-HERO..webp")no-repeat;
        background-size: cover;
    }

    .hero-text {
        text-align: center;
    }

    .hero-content {
        margin: 50px auto;
    }

    .cards {
        max-width: 390px !important;
        perspective: 1000px;
    }

    .hero-text p {
        color: #f1f1f19b;
        width: 100%;
    }

    .hero-text h1 {
        font-size: 32px;
        width: 99%;
        margin-right: auto;
        margin-left: auto;
        padding: 2px 0px;
    }

    input {
        margin: 0px;
        border: 0px;
        color: #000000;
        padding: 12px;
        font-weight: bold;
        background-color: transparent;
        width: 77%;
        margin-right: auto;
        margin-left: auto;
        max-height: 50px;
        min-width: 200px;
        border: none;
        outline: none;
    }

    video {
        width: 95% !important;
        max-width: 900px;
        display: block;
        margin: 0 auto;
        border-radius: 12px;
    }

    .hero-button {
        display: block;
        margin: 12px auto;
    }

    .hero-button-dekstop {
        display: none;
    }

    /* ===== Home Section End from here ===== */

    /* ===== Section Header START from here ===== */

    .section-header h2 {
        font-size: 21px !important;
    }

    .section-header s {
        font-size: 14px !important;
    }

    .hei-180 {
        height: 82px !important;
    }

    /* ===== About us2 start from here ===== */

    #About-us2 {
        height: 700px;
        padding: 90px 0px;
    }

    /* ===== About us2 end from here ===== */

    /* ===== How it works Section Start from here ===== */
    #How-it-works {
        height: 585px;
        padding: 64px 0px !important;
    }

    .steps-title span {
        font-size: 21px;
    }

    .steps-content s {
        font-size: 14px;
    }

    /* ===== How it works Section END from here ===== */

    /*===== Faq accirdion START from here ===== */
    #faq {
        height: 1140px;
    }

    .faq-container {
        text-align: center;
        padding: 0 5px;
        min-height: 80px;
        white-space: nowrap;
    }

    .accordion .fa-angle-down {
        display: none;
    }

    .accordion {
        margin: 50px 0px;
        justify-content: center;
    }

    /*===== Faq accirdion END from here =====*/
    /*===== About us Section Start from here =====*/
    #About-us {
        height: 800px !important;
        padding: 40px 0px !important;
    }

    .About-us-left .title {
        font-size: 32px !important;
    }

    .About-us-right-img {
        display: none !important;
    }

    .About-us-left {
        position: relative;
        margin: 10px 0px !important;
    }

    .About-us-mob-img {
        display: block !important;
        max-width: 520px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
    }

    .About-us-mob-img img {
        width: 100%;
        height: 100%;
        position: relative;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .About-us-mob-text {
        display: block !important;
        font-size: 28px;
        text-align: center;

    }

    /*===== About us Section End from here =====*/

    .writer-text-hero {
        width: 90%;
        height: 60px;
        margin: 220px auto !important;
        background-color: #0A0825;
        border-radius: 12px;
        border: 2px solid #30dd8a;
        opacity: 0.8 !important;
        backdrop-filter: blur(1px);
    }

}

@media (min-width: 991.98px) and (max-width: 1200px) {

    /* ===== Navbar Section START from here ===== */
    .navbar-nav {
        margin: 0px 20px;
    }

    .nav-item a {
        display: flex;
        margin: 0px 5px;
        font-size: 15px;
    }

    .footer-buttons {
        margin: 0px 44px;
    }

    /* ===== Navbar Section END from here ===== */

    /* ===== home Section Start from here ===== */

    .hero-text h1 {
        font-size: 40px;
        width: 99%;
        margin-right: auto;
        margin-left: auto;
        padding: 2px 0px;
    }

    #home {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 880px;
        background: url("assets/images/BG-HERO..webp")no-repeat;
        background-size: 1300px;
    }

    /* ===== home Section End from here ===== */

    /* ===== About Us Section Start from here ===== */
    #About-us {
        height: 520px;
    }

    .About-us-right {
        display: block;
        margin-left: auto;
        margin-right: auto;
        backdrop-filter: none;
    }

    .About-us-right img {
        justify-content: center;
    }

    .About-us-left .title {
        font-size: 32px !important;
    }

    .About-us-left .des {
        font-size: 17px;
    }

    .About-hero-btn {
        margin: 20px 0px;
    }

    .about-btn {
        display: none !important;
    }

    /* ===== About Us Section END from here ===== */
}


@media (min-width: 991.98px) {

    /* ===== Navbar Section START from here ===== */
    .navbar .nav-item .dropdown-menu {
        display: block;
        opacity: 0;
        visibility: hidden;
        transition: .3s;
        margin-top: 0;
        background: #181841;
        color: #181841;
        min-width: 170px;
        padding: 8px;
        backdrop-filter: blur(40px) !important;
    }

    .dropdown-menu li a {
        border-bottom: transparent !important;
    }

    .navbar .dropdown-menu.fade-up {
        top: 180%;
    }

    .navbar .nav-item:hover .dropdown-menu {
        transition: .2s;
        opacity: 1;
        visibility: visible;
        top: 100%;
        transform: rotateX(0deg);
        box-shadow: 5px 2px 60px 0px #181841;
        margin: 7px 0px;
        border: transparent;
    }

    .navbar .nav-item:hover .dropdown-menu::before {
        content: '';
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #181841;
        position: absolute;
        top: -10px;
        right: 126px;
    }

    .dropdown-menu li a {
        color: #fff;
        margin: 0px;
        font-weight: 550;
    }

    .dropdown-menu li a:hover {
        background-color: transparent;
        color: #fff;
    }

    .navbar .nav-item:hover .fa-angle-down {
        transform: rotate(180deg);
        transition: 0.25s all ease-in-out;
    }

    .nav-item a {
        display: flex;
        margin: 0px 8px;
        white-space: nowrap;
        padding: 10px;
    }

    .nav-item i {
        margin: 8px;
        opacity: 0.8;
    }

    .footer-buttons button {
        margin: 8px;
        font-size: 16px;
        white-space: nowrap;
        padding: 10px;
    }

    /* ===== Navbar Section END from here ===== */


}

@media (max-width: 768px) {

    #home {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 700px;
        background: url("assets/images/BG-HERO..webp")no-repeat;
        background-size: cover;
    }

    /* ===== How it works Section Start from here ===== */
    #How-it-works {
        height: 1153px;
        border-radius: 0px;
        padding: 16px 0px !important;
    }

    .steps-col {
        margin: 38px 20px;
    }

    /* ===== How it works Section END from here ===== */
}

@media (max-width: 518px) {

    /* ===== Home Section Start from here ===== */
    #home {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 720px;
        background: url("assets/images/BG-HERO..webp")no-repeat;
        background-size: cover;
    }

    /* ===== Home Section End from here ===== */
}

@media (max-width: 445px) {
    /* ===== Section Header START from here ===== */

    .section-header h2 {
        font-size: 21px !important;
    }

    .cards {
        max-width: 380px !important;

    }

    .writer-text-hero {
        margin: 214px auto !important;
    }

}


@media (max-width: 390px) {

    .cards {
        max-width: 350px !important;
    }

    .writer-text-hero {
        margin: 190px auto !important;

    }

}