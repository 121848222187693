  @media (max-width: 991.98px) {

      /* ===== Navbar Section START from here ===== */

      .footer-nav {
          z-index: 100 !important;
          background-color: #08061F5b !important;
          border-radius: 0px !important;
      }

      .navbar-nav {
          margin: 0px;
      }

      .navbar-collapse {
          position: absolute;
          top: 99%;
          right: 0;
          left: 0;
          margin-top: 10px;
          transition: .1s !important;
          background-color: #08061F9b !important;
          border-radius: 0px;
          padding: 15px;
          z-index: 1000;
          overflow: hidden;
          overflow-y: hidden;
          max-height: 150vh;
          width: 100%;
          backdrop-filter: blur(4px);
      }

      .navbar-brand {
          margin: 0px 10px;
      }

      .nav-item {
          color: #000;
      }

      .nav-item a {
          margin: 0px 6px;
      }

      #nav-icon {
          margin: 0px;
      }

      .dropdown-menu.show {
          position: relative;
          display: block;
          background-color: transparent;
          color: #000;
          margin: 0px;
          border: transparent;
          transition: opacity 200ms linear, max-height 200ms linear;
      }

      .dropdown-toggle .icon {
          float: right;
          padding: 8px;
          display: flex;
      }

      .dropdown-menu li a {
          color: #000;
          border-top: 1px solid rgba(255, 255, 255, 0.041);
      }

      .dropdown-menu li a:hover {
          background-color: transparent;
          color: #000;
      }

      .footer-buttons {
          text-transform: uppercase;
          column-gap: 1px;
          display: flex;
          margin: 0px;
      }

      .footer-buttons button {
          margin: 8px;
          font-size: 16px;
          backdrop-filter: none !important;
      }

      .footer-buttons i {
          font-size: 16px;
          margin: 2px 8px;
      }

      /* ===== Navbar Section END from here ===== */
      /* ===== Home Page START from here ===== */
      .Generation-hero {
          margin: 20px 0px;
      }

      .hero-contents {
          width: 90%;
          margin: 0 auto;
      }

      h1 {
          font-size: 32px;
          width: 99%;
          margin-right: auto;
          margin-left: auto;
      }

      #text-display {
          display: inline;
      }

      video {
          width: 95% !important;
          max-width: 900px;
          display: block;
          margin: 0 auto;
          border-radius: 12px;
      }

      .search-input textarea {
          width: 90%;
      }


      .tag {
          padding: 6px;
          margin: 0px 6px;
      }

      .text-hero {
          display: inline;
          margin: 0px auto;
      }

      .negative_prompt_text {
          margin: 20px 0;
      }

      .negative_prompt_text input {
          width: 80%;
          height: 40px;
          padding: 20px 10px;
          box-shadow: 2px 1px 6px 1px #00000009;
          border-radius: 8px;
          margin: 2px auto;
      }

      .bottom-inputs {
          display: flex;
          margin: 0px;
      }

      .color-1 {
          width: 90%;
          padding: 14px;
      }

   
      /* ===== Home Page END from here ===== */

  }

  @media (min-width: 991.98px) and (max-width: 1200px) {



      /* ===== Navbar Section START from here ===== */
      .navbar-nav {
          margin: 0px 20px;
      }

      .footer-buttons {
          margin: 0px 44px;
      }

      /* ===== Navbar Section END from here ===== */
  }



  @media (min-width: 991.98px) {

      /* ===== Navbar Section START from here ===== */
      .navbar .nav-item .dropdown-menu {
          display: block;
          opacity: 0;
          visibility: hidden;
          transition: .3s;
          margin-top: 0;
          background: #181841;
          color: #181841;
          min-width: 170px;
          padding: 8px;
          backdrop-filter: blur(40px) !important;
      }

      .dropdown-menu li a {
          border-bottom: transparent !important;
      }

      .navbar .dropdown-menu.fade-up {
          top: 180%;
      }

      .navbar .nav-item:hover .dropdown-menu {
          transition: .2s;
          opacity: 1;
          visibility: visible;
          top: 100%;
          transform: rotateX(0deg);
          box-shadow: 5px 2px 60px 0px #181841;
          margin: 7px 0px;
          border: transparent;
      }

      .navbar .nav-item:hover .dropdown-menu::before {
          content: '';
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid #181841;
          position: absolute;
          top: -10px;
          right: 126px;
      }

      .dropdown-menu li a {
          color: #fff;
          margin: 0px;
          font-weight: 550;
      }

      .dropdown-menu li a:hover {
          background-color: transparent;
          color: #fff;
      }

      .navbar .nav-item:hover .fa-angle-down {
          transform: rotate(180deg);
          transition: 0.25s all ease-in-out;
      }

      .nav-item a {
          display: flex;
          margin: 0px 8px;
      }

      .nav-item i {
          margin: 8px;
          opacity: 0.8;
      }

      /* ===== Navbar Section END from here ===== */
  }