   /* ===== HOME Section START from here ===== */

   .loader-g {
       background: #000000;
       width: 100%;
       height: 100%;
       position: fixed;
       top: 0;
       left: 0;
       display: flex;
       justify-content: center;
       align-items: center;
       z-index: 100;
       overflow: hidden;
       margin: 0px;
   }

   /* Disable scrolling on the body when loader is displayed */
   body.disable-scroll {
       overflow: hidden;
   }

   .Generate {
       box-sizing: border-box;
       text-decoration: none;
       moz-transition: all .4s ease-in-out;
       -o-transition: all .4s ease-in-out;
       -webkit-transition: all .4s ease-in-out;
       transition: all .4s ease-in-out;
       /* Set an appropriate height */
       margin: 0px !important;
       padding: 0px !important;
   }

   body {
       font-family: 'Poppins', sans-serif;
       color: #ffffff;
       background-color: #0A0825 !important;
       box-sizing: border-box;
   }

   * {
       box-sizing: border-box;
       text-decoration: none;
       moz-transition: all .4s ease-in-out;
       -o-transition: all .4s ease-in-out;
       -webkit-transition: all .4s ease-in-out;
       transition: all .4s ease-in-out;
   }

   /* ===== Navbar Section START from here ===== */
   /*Navbar Start */

   .footer-nav {
       width: 100%;
       z-index: 10;
       position: relative;
       margin-right: auto;
       margin-left: auto;
       margin: 0px auto;
   }


   .navbar-brand {
       font-weight: bold;
   }

   .navbar-brand a {
       font-size: 21px;
       font-weight: 580;
       color: #f1f1f1;
       text-decoration: none;
       text-shadow: 4px 2px 20px 0px #201E2040;
   }

   .navbar-brand a span {
       transition: all 0.3s ease;
   }

   .navbar-nav {
       margin: 0px auto;
   }

   .nav-item {
       justify-content: space-around;
       color: #f1f1f1;
       margin: 0px 8px;
   }

   .nav-item a {
       font-size: 17px;
       font-weight: lighter;
       opacity: 0.7;
   }

   .nav-item i {
       font-size: 14px;
   }

   .navbar-toggler {
       border: transparent;
       color: transparent;
   }

   .bg-body-tertiary {
       background-color: transparent !important;
   }

   .cs-dark .cs-white_bg,
   .cs-dark .cs-nav .cs-nav_list ul {
       background-color: #283645;
   }

   .navbar {
       line-height: 2rem !important;
   }

   .navbar .navbar-brand {
       color: #FFF !important;
   }

   .navbar-nav .nav-item .nav-link {
       color: #ffffff;
   }

   .dropdown-menu.show {
       display: none;
   }

   .dropdown-toggle::after {
       display: none;
       margin: 0px;
   }

   .fa-angle-down {
       display: inline-block;
       position: relative;
       float: right;
       transform: rotate(0);
   }

   .nav-link[aria-expanded=true] .fa-angle-down {
       transform: rotate(180deg);
       transition: 0.25s all ease-in-out;
   }

   .nav-link {
       font-size: 21px;
       color: #ffffff;
   }

   .nav-link:hover {
       color: #ffffff;
   }

   /*navbar buttons*/

   .footer-buttons {
       text-transform: uppercase;
       align-items: center;
       display: flex;
       float: right;
   }

   .footer-buttons i {
       margin: 2px 12px;
       font-size: 16px;
       float: right;
   }

   #nav-icon {
       width: 35px;
       height: 35px;
       position: relative;
       margin: 0px auto !important;
       -webkit-transform: rotate(0deg);
       -moz-transform: rotate(0deg);
       -o-transform: rotate(0deg);
       transform: rotate(0deg);
       -webkit-transition: .5s ease-in-out;
       -moz-transition: .5s ease-in-out;
       -o-transition: .5s ease-in-out;
       transition: .5s ease-in-out;
       cursor: pointer;
       /*   border:1px solid red; */
   }

   #nav-icon span {
       display: block;
       position: absolute;
       height: 3px;
       width: 100%;
       background: #ffffff;
       border-radius: 9px;
       opacity: 1;
       right: 0;
       -webkit-transform: rotate(0deg);
       -moz-transform: rotate(0deg);
       -o-transform: rotate(0deg);
       transform: rotate(0deg);
       -webkit-transition: .25s ease-in-out;
       -moz-transition: .25s ease-in-out;
       -o-transition: .25s ease-in-out;
       transition: .25s ease-in-out;
       /*   border:4px solid black; */
   }

   #nav-icon span:nth-child(1) {
       top: 0px;
       -webkit-transform-origin: left center;
       -moz-transform-origin: left center;
       -o-transform-origin: left center;
       transform-origin: left center;
       width: 70%;
   }


   #nav-icon span:nth-child(2) {
       top: 10px;
       -webkit-transform-origin: left center;
       -moz-transform-origin: left center;
       -o-transform-origin: left center;
       transform-origin: left center;
   }

   #nav-icon span:nth-child(3) {
       top: 20px;
       -webkit-transform-origin: left center;
       -moz-transform-origin: left center;
       -o-transform-origin: left center;
       transform-origin: left center;
       width: 70%;
   }

   #nav-icon.open span:nth-child(1) {
       -webkit-transform: rotate(45deg);
       -moz-transform: rotate(45deg);
       -o-transform: rotate(45deg);
       transform: rotate(45deg);
       top: -3px;
       left: 2px;
       width: 100%;
   }

   #nav-icon.open span:nth-child(2) {
       width: 0%;
       opacity: 0;
   }

   #nav-icon.open span:nth-child(3) {
       -webkit-transform: rotate(-45deg);
       -moz-transform: rotate(-45deg);
       -o-transform: rotate(-45deg);
       transform: rotate(-45deg);
       top: 22px;
       left: 2px;
       width: 100%;
   }

   /* ===== Navbar Section END from here ===== */

   /* ===== BUTTONS Start from here ===== */

   .btn {
       text-align: center;
       display: flex;
       cursor: pointer;
       font-size: 14px;
       justify-content: center;
       align-items: center;
       text-transform: uppercase;
       font-weight: 600;
   }

   .btn-hover {
       font-size: 16px;
       font-weight: normal;
       color: #fff;
       padding: 15px 30px;
       cursor: pointer;
       text-align: center;
       border: none;
       background-size: 300% 100%;
       border-radius: 9px;
       moz-transition: all .4s ease-in-out;
       -o-transition: all .4s ease-in-out;
       -webkit-transition: all .4s ease-in-out;
       transition: all .4s ease-in-out;
   }


   .btn-hover:hover {
       background-position: 100% 0;
       moz-transition: all .4s ease-in-out;
       -o-transition: all .4s ease-in-out;
       -webkit-transition: all .4s ease-in-out;
       transition: all .4s ease-in-out;
   }

   .btn-hover:focus {
       outline: none;
   }

   .btn-hover.color-4 {
       background-image: linear-gradient(to right, #0ba360, #3cba92, #30dd8a, #2bb673);
       box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75);
   }

   .btn-hover.nav-btn {
       background-image: linear-gradient(to right, #f1f1f1, #f1f1f18b, #f1f1f1, #fff);
       border: 2px solid #0000002b;
       color: #f1f1f1d4;
       box-shadow: none;
   }

   /* ===== BUTTONS END from here ===== */


   /* ===== About Us Section Start from here ===== */

   #About-us {
       height: 530px;
       padding: 80px 0px;
   }

   .About-us-mob-img {
       display: none;
   }

   .About-us-mob-text {
       display: none;

   }

   .About-us-right-img {
       position: relative;
       margin-left: auto;
       margin-right: auto;
       max-width: 880px;
   }

   .About-us-right-img img {
       width: 100%;
       height: 100%;
       position: relative;
       display: block;
       margin-left: auto;
       margin-right: auto;
   }

   .About-us-left {
       position: relative;
       margin: -20px 0px;
   }

   .About-us-left .title {
       font-size: 46px;
       font-weight: bolder;
       color: #f1f1f1;
       margin: 25px 0px;
   }

   .About-us-left .des {
       font-size: 17px;
       color: #f1f1f19b;
   }

   .About-hero-btn {
       margin: 25px 0px;
       display: flex;
   }

   /* ===== About Us Section End from here ===== */
   /* ===== Section Header START from here ===== */

   .section-header {
       position: relative;
       font-weight: bold;
   }

   .section-header h2 {
       font-size: 38px;
       font-weight: bold;
       margin: 15px 0px;
       color: #f1f1f1!important;
   }

   .section-header s {
       max-inline-size: 600px;
       margin: auto;
       font-weight: 310;
       opacity: 0.8;
       text-transform: capitalize;
       font-size: 18px;
   }

   /* ===== About Us Section END from here ===== */

   /*===== Site footer START Start from here ===== */

   .gen-site-footer {
       padding: 45px 0 20px;
       font-size: 15px;
       line-height: 24px;
       color: #2BAE66;
   }

   .copyright-text {
       text-align: center;
   }

   /* social icons */

   .social-icons {
       padding-left: 0;
       list-style: none;
   }

   /*===== Site footer END Start from here ===== */


   /* ===== BUTTONS START from here ===== */

   .btn {
       text-align: center;
       display: flex;
       cursor: pointer;
       font-size: 14px;
       justify-content: center;
       align-items: center;
       text-transform: uppercase;
       font-weight: 600;
   }

   .btn-hover {
       font-size: 16px;
       font-weight: normal;
       color: #fff;
       padding: 15px 30px;
       cursor: pointer;
       text-align: center;
       border: none;
       background-size: 300% 100%;
       border-radius: 9px;
       moz-transition: all .4s ease-in-out;
       -o-transition: all .4s ease-in-out;
       -webkit-transition: all .4s ease-in-out;
       transition: all .4s ease-in-out;
   }

   .btn-hover:hover {
       background-position: 100% 0;
       moz-transition: all .4s ease-in-out;
       -o-transition: all .4s ease-in-out;
       -webkit-transition: all .4s ease-in-out;
       transition: all .4s ease-in-out;
   }

   .btn-hover:focus {
       outline: none;
   }

   .btn-hover.color-4 {
       background-image: linear-gradient(to right, #0ba360, #3cba92, #30dd8a, #2bb673);
       box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75);
   }

   .btn-hover.nav-btn {
       background-image: linear-gradient(to right, #f1f1f1, #f1f1f18b, #f1f1f1, #fff);
       border: 2px solid #0000002b;
       color: #f1f1f1d4;
       box-shadow: none;
   }

   /* ===== BUTTONS END from here ===== */

   /* Style for the download button */
   .download-button {
       background-color: #27ae60;
       color: white;
       padding: 5px 10px;
       border-radius: 10px;
       margin: 100px 0px;
       font-weight: bold;
       text-decoration: none;
       position: absolute;
       opacity: 0;
       /* Initially set opacity to 0 */
       transition: opacity 0.4s ease;
       /* Define the transition property */
   }

   .heart-icon {
       position: absolute;
       margin: 160px 0px;
       font-size: 50px;
       cursor: pointer;
       opacity: 0;
       /* Initially set opacity to 0 */
       transition: opacity 0.4s ease;
       /* Define the transition property */
   }

   .deleteButton {
       padding: 5px 0px;
       font-weight: bold;
       opacity: 0;
       /* Initially set opacity to 0 */
       transition: opacity 0.5s ease;
       /* Define the transition property */
   }

   .custom-image-container div:hover .download-button {
       opacity: 1;
       animation: animateLeftToRight 0.3s ease;
       /* Use the animation on hover */
       animation-fill-mode: forwards;
       /* Keep the final style after animation */
   }

   .custom-image-container div:hover .heart-icon {
       opacity: 1;
       animation: animateLeftToRight 0.3s ease;
       /* Use the animation on hover */
       animation-fill-mode: forwards;
       /* Keep the final style after animation */
   }

   .custom-image-container div:hover p {
       opacity: 1;
       animation: animateLeftToRight 0.3s ease;
       /* Use the animation on hover */
       animation-fill-mode: forwards;
       /* Keep the final style after animation */
   }

   /* Keytag-heros for the left-to-right animation */
   @keytag-heros animateLeftToRight {
       0% {
           transform: translateX(-50%);
           /* Start from left */
           opacity: 0;
           /* Initially hidden */
       }

       100% {
           transform: translateX(0);
           /* End at center */
           opacity: 1;
           /* Fully visible */
       }
   }

   style attribute {
       color: #2c54b1;
   }

   /* Style the heart icon when it's red */
   .heart-icon.active {
       color: red;
   }

   /* Style for the loader */
   .loader {
       display: none;
       position: fixed;
       top: 50%;
       left: 50%;
       transform: translate(-50%, -50%);
       z-index: 9999;
       font-size: 24px;
       font-weight: bold;
       background-color: #0000006b;
       color: #ffffff;
       width: 100%;
       height: 100%;
       justify-content: center;
       text-align: center;
       align-items: center;
       padding: 400px 0px;
   }

   #loader2 {
       position: absolute;
       top: calc(63% - 20px);
       left: calc(50% - 20px);
   }

   @keytag-heros loader {
       0% {
           left: -100px
       }

       100% {
           left: 110%;
       }
   }

   #box {
       width: 50px;
       height: 50px;
       background: #fff;
       animation: animate .5s linear infinite;
       position: absolute;
       top: 0;
       left: 0;
       border-radius: 3px;
   }

   @keytag-heros animate {
       17% {
           border-bottom-right-radius: 3px;
       }

       25% {
           transform: translateY(9px) rotate(22.5deg);
       }

       50% {
           transform: translateY(18px) scale(1, .9) rotate(45deg);
           border-bottom-right-radius: 40px;
       }

       75% {
           transform: translateY(9px) rotate(67.5deg);
       }

       100% {
           transform: translateY(0) rotate(90deg);
       }
   }

   #shadow {
       width: 50px;
       height: 5px;
       background: #000;
       opacity: 0.1;
       position: absolute;
       top: 59px;
       left: 0;
       border-radius: 50%;
       animation: shadow .5s linear infinite;
   }

   @keytag-heros shadow {
       50% {
           transform: scale(1.2, 1);
       }
   }

   .row-container {
       display: inline-block;
       padding: 40px 80px;
       height: 700px;
   }

   .search-content {
       width: 100%;
       height: 640px;
   }

   .Generation-hero {
       margin-right: auto;
       margin-left: auto;
       text-align: center;
       margin: 40px 0px;
   }

   h1 {
       font-size: 62px;
       font-weight: bolder;
       width: 60%;
       color: #ffffff;
       margin-right: auto;
       margin-left: auto;
       padding: 10px 0px;
   }

   .Generation-hero p {
       color: #f1f1f19b;
   }

   .hero-contents {
       width: 66%;
       margin: 0 auto;
   }

   /* ... other styles ... */

   .search-input {
       height: 110px;
       display: flex;
       justify-content: center;
       align-items: center;
       position: relative;
   }

   .search-input .Search-hero {
       width: 100%;
       border-radius: 10px;
       text-align: center;
       position: relative;
       background-color: transparent;
       border: none;
       z-index: 1 !important;
       /* Adjust the z-index */
   }

   .search-input .Search-hero::before {
       content: "";
       position: absolute;
       top: -2.2px;
       left: -2.6px;
       right: -2.6px;
       bottom: -2.2px;
       z-index: -1;
       border-radius: 12px;
       background-image: linear-gradient(to right, #0ba360, #3cba92, #30dd8a, #2bb673);
   }

   .search-input .Search-hero::after {
       content: "";
       position: absolute;
       top: 0px;
       left: 0px;
       right: 0px;
       bottom: 0px;
       z-index: -1;
       border-radius: 12px;
       background-color: #0A0825;
   }

   /* ... other styles ... */

   #prompt::-webkit-scrollbar {
       width: 5px;
   }

   #prompt::-webkit-scrollbar-track {
       background: transparent;
   }

   #prompt::-webkit-scrollbar-thumb {
       background: transparent;
       border-left: 2px solid #f1f1f15b;
   }

   .search-input textarea {
       width: 95%;
       height: 110px;
       /* Adjusted height for the textarea */
       padding: 20px;
       float: left;
       font-size: 16px;
       background-color: transparent;
       box-sizing: border-box;
       border: transparent;
       resize: vertical;
       outline: none;
       resize: none;
       display: block;
       margin: 0 auto;
       line-height: normal;
       color: #ffffff;
   }

   .cancel-button {
       position: absolute;
       top: 10px;
       right: 10px;
       cursor: pointer;
       color: #ffffff5b;
       backdrop-filter: blur(50px);
       font-size: 20px;

       /* Hide the button by default */
   }

   .word-count {
       position: absolute;
       bottom: 12px;
       right: 10px;
       color: #ffffffd0;

       /* Hide the word count by default */
   }

   .hero-model-like img {
       height: auto;
       width: 66px;
       height: 66px;
       display: flex;
       display: block;
       justify-content: center;
       align-items: center;
       transition: transform 0.6s ease;
   }

   .custom-image-container {
       text-align: center;
       position: relative;
   }

   .custom-image-container .hero-gen-image {
       position: fixed;
       top: 0;
       left: 0;
       right: 0;
       max-width: 415px;
       margin: 0 auto;
       display: flex;
       justify-content: center;
       align-items: center;
   }

   .custom-image-container img {
       max-width: 100%;
       height: auto;
       margin: 0 auto;
       transition: transform 0.6s ease;
       pointer-events: none;
   }

   .blur img {
       filter: blur(4.6px) !important;
   }

   button {
       padding: 11px;
       border: 0px;
       background-color: transparent !important;
       border-radius: 8px;
       color: #ffff;
       backdrop-filter: blur(50px);
   }


   button {
       -webkit-appearance: none;
       -moz-appearance: none;
       appearance: none;
       border: none;
       font-size: inherit;
       border-radius: 16px;
       color: white;
       display: inline-flex;
       align-items: center;
       cursor: pointer;
       font-weight: bold;
   }

   .hero-gen-button {
       height: 90px;
       display: flex;
       align-items: center;
       justify-content: center;
       background-color: #0A0825;
       position: fixed;
       width: 100%;
       left: 50%;
       transform: translateX(-50%);
       bottom: 0px;
   }

   .hero-gen-button i {
       margin: 0px 4px;
   }

   .color-1 {
       padding: 16px;
       text-align: center;
       position: fixed;
       width: 66%;
       display: flex;
       align-items: center;
       justify-content: center;
       cursor: pointer;
       border: none;
       background-size: 300% 100%;
       border-radius: 9px;
       background-image: linear-gradient(to right, #0ba360, #3cba92, #30dd8a, #2bb673);
       moz-transition: all .4s ease-in-out;
       -o-transition: all .4s ease-in-out;
       -webkit-transition: all .4s ease-in-out;
       transition: all .4s ease-in-out;
   }

   .color-1:hover {
       background-position: 100% 0;
       moz-transition: all .4s ease-in-out;
       -o-transition: all .4s ease-in-out;
       -webkit-transition: all .4s ease-in-out;
       transition: all .4s ease-in-out;
   }

   .color-1:focus {
       outline: none;
   }

   .color-5 {
       padding: 15px 20px;
       text-align: center;
       margin: 6px auto;
       display: flex;
       font-weight: 400;
       font-size: 15px;
       align-items: center;
       justify-content: center;
       cursor: pointer;
       border: none;
       background-size: 300% 100%;
       border-radius: 9px;
       background-image: linear-gradient(to right, #0ba360, #3cba92, #30dd8a, #2bb673);
       moz-transition: all .4s ease-in-out;
       -o-transition: all .4s ease-in-out;
       -webkit-transition: all .4s ease-in-out;
       transition: all .4s ease-in-out;
   }

   .color-5 span {
       margin: 0px 4px;
   }

   .color-5:hover {
       background-position: 100% 0;
       moz-transition: all .4s ease-in-out;
       -o-transition: all .4s ease-in-out;
       -webkit-transition: all .4s ease-in-out;
       transition: all .4s ease-in-out;
   }

   .color-5:focus {
       outline: none;
   }

   .space {
       position: relative;
       height: 60px;
       width: 100%;
   }

   /* CSS */
   .button-container {
       /* Initially hide the button */
       opacity: 0;
       transform: translateY(20px);
       transition: opacity 0.5s ease, transform 0.5s ease;
   }

   .button-container.show {
       /* Display the button after 2 seconds */
       opacity: 1;
       transform: translateY(0);
   }


   /* Style for the delete button */
   .custom-image-container p {
       margin-top: 10px;
       /* Add some spacing between the image and the delete button */
       position: absolute;
       background-color: red;
       cursor: pointer;
       color: #ffffff;
       padding: 18px;
       border-radius: 50%;
       backdrop-filter: blur(50px);
   }


   .text-hero {
       display: flex;
       flex-direction: row;
       margin: 0px auto;
       text-align: start;
       justify-content: start;
       align-items: start;
   }


   input {
       outline: none;
   }

   .negative_prompt_text p {
       color: #ffffff;
       margin: 7px 2px;
   }

   .negative_prompt_text input {
       width: 100%;
       height: 40px;
       padding: 20px 10px;
       box-shadow: 2px 1px 6px 1px #00000009;
       background-color: transparent;
       box-sizing: border-box;
       border: 2px solid #2bb6738b;
       border-radius: 8px;
       color: #ffffff;
       font-size: 14px;
       outline: none;
   }

   .negative_prompt_text input::placeholder {
       color: #f1f1f19b;
       font-weight: lighter;
   }

   .negative_prompt_text input:disabled {
       background-color: #f1f1f19b;
       /* Gray out the input when disabled */
       cursor: not-allowed;
       /* Show not-allowed cursor when disabled */
   }

   .bottom-inputs {
       display: flex;
       margin: 0px 30px;
   }

   .bottom-inputs p {
       text-align: start;
       color: #ffffff;
       font-weight: lighter;
       margin: 6px 2px;
   }

   .input-contents {
       display: flex;
       flex-direction: column;
       margin: 2px 5px;
   }


   .bottom-inputs input {
       min-width: 90px;
       max-width: 90px;
       background-color: transparent;
       border: 2px solid #2bb6738b;
       box-shadow: 2px 1px 6px 1px #ffffff04;
       border-radius: 8px;
       height: 40px;
       padding: 20px 10px;
   }

   .bottom-inputs input {
       color: #ffffff;
   }

   .bottom-inputs input::placeholder {
       color: #f1f1f19b;
       font-weight: lighter;
   }

   .bottom-inputs input:-webkit-autofill {
       -webkit-box-shadow: 0 0 0 1000px #ffffff inset;
       -webkit-text-fill-color: #000000;
   }

   .Display-models {
       margin: 15px auto;
   }

   .Display-models s {
       margin: 10px 0px;
       text-align: start;
       font-weight: 550 !important;
       font-size: 18px !important;
       color: #f1f1f1 !important;
   }

   .hero-model-like {
       margin: 12px 0px !important;
       display: flex;
   }

   .model-img {
       cursor: pointer;
       margin: auto -10px;
       width: 100px;
       height: 100px;
       justify-content: center;
       align-items: center;
       transition: transform 0.6s ease;
   }

   .model-img p {
       position: absolute;
       margin: 60px 10px !important;
       background-color: #2bb673;
       padding: 2px 12px;
       font-size: 10px;
       box-shadow: 4px 4px 20px 0px #2bb6733b;
       border-radius: 16px;
       color: #ffffff !important;
   }

   .model-img box {
       background-color: #f1f1f10b;
       display: flex;
       justify-content: center;
       align-items: center;
       height: 70px;
   }

   .model-img.clicked img {
       outline: 2px solid #fac820;
   }

   .model-img.clicked .box {
       opacity: 1;
   }

   .model-img box i {
       font-size: 19px;
   }

   .model-img img {
       margin: -4px 10px !important;
   }

   .image--cover {
       width: 70px;
       height: 70px;
       border-radius: 12px;
       -o-object-fit: cover;
       float: left;
       object-fit: cover;
       -o-object-position: center right;
       object-position: center right;
   }

   .model--cover {
       width: 70px;
       height: 70px;
       border-radius: 50%;
       -o-object-fit: cover;
       float: left;
       object-fit: cover;
       -o-object-position: center right;
       object-position: center right;
       filter: saturate(1.4);
   }

   .bordered {
       outline: 2px solid #2bb673;
       /* Change this to your desired border color */
       filter: saturate(1.5);
   }

   .model-title {
       display: flex;
   }

   .model-title p {
       margin: auto 8px;
       font-size: 11px;
   }

   .bordered2 {
       outline: 2px solid #2bb673;
   }

   .pro-tag {
       position: relative;
       font-size: 10px;
       color: #ffff;
       text-align: right;
       background-color: red;
       padding: 1px 3px;
       border-radius: 5px;
       height: 18px;
       width: 25px;
       margin: -12px 40px;
   }

   .btn-onof-contents {
       display: inline;
       white-space: nowrap;
       text-overflow: ellipsis;
       /* Show ellipsis (...) for overflowed content */
       margin: 5px 20px;
   }

   .btn-onoff {
       display: flex;
       justify-content: center;
       align-items: center;
       cursor: pointer;
       position: relative;
       width: 80px;
       height: 34px;
       margin: 6px;
   }

   .btn-onoff input {
       position: absolute;
       opacity: 0;
   }

   .btn-onoff span {
       display: block;
       width: 80px;
       height: 34px;
       border-radius: 40px;
       background: #ccccccd3;
       border: 1px solid #ccc;
       transition: 400ms;
       position: relative;
       /* Add this line */
       margin: 0 auto;
       /* Add this line */
   }

   .btn-onoff input:checked~span {
       border: 1px solid #4CAF50;
       background: #4CAF50;
   }

   .btn-onoff input~span:before {
       content: 'Off';
       display: block;
       width: 34px;
       height: 34px;
       position: absolute;
       right: 0;
       top: 0;
       /* Add this line */
       font-size: 14px;
       font-family: "Arial";
       font-weight: bold;
       line-height: 34px;
       color: #444;
       padding: 0;
       letter-spacing: 1px;
       text-transform: uppercase;
       transition: 500ms;
   }

   .btn-onoff input:checked~span:before {
       content: 'On';
       right: auto;
       left: 25px;
       /* Adjusted this line */
       color: #fff;
   }

   .btn-onoff input~span:after {
       content: '';
       position: absolute;
       width: 26px;
       height: 26px;
       top: 4px;
       left: 4px;
       background: #fff;
       border-radius: 50%;
       transition: 400ms;
   }

   .btn-onoff:hover input~span:after {
       transform: scale(0.9);
   }

   .btn-onoff input:checked~span:after {
       left: 51px;
   }