.type-writer-text {
    position: absolute;
    bottom: 0;
    /* Adjust the value to your preference */
    left: 50%;
    transform: translateX(-50%);
    color: #ffffff!important;
    font-size: 18px;
    font-weight: 400;
    font-family: 'Anonymous Pro', monospace;
    border-right: 2px solid #dedede;
    white-space: nowrap;
    /* Prevent text from wrapping */
    overflow: hidden;
    /* Hide overflowing content */
    max-width: 100%;
    /* Limit the width to the container */
    animation: typewriter 8s steps(50) 0.5s 1 normal both,
        cursorblink 0.5s infinite;
    background-color: #f1f1f11b;
    backdrop-filter: blur(1px);
}

@keyframes typewriter {
    from {
        width: 0;
    }

    to {
        width: 100%;
        /* Change this to 100% */
    }
}

@keyframes cursorblink {
    from {
        border-right: 2px solid #dedede;
    }

    to {
        border-right: 2px solid #666;
    }
}