.App {
    position: relative;
    width: 100%;
    height: 100vh;
    font-family: 'Poppins', sans-serif;
    color: #ffffff;
    background-color: #0A0825 !important;
    box-sizing: border-box;
}

.content {
    display: grid;
    place-items: center;
    height: 96vh;
}

.login-container,
.register-container {
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    height: 100vh;
    /* Ensure a minimum height of the viewport */
}

@media (max-width: 768px) {
    .App {
        grid-template-columns: 1fr;
        /* Adjust columns for mobile */
        padding: 0px;
        /* Add padding to avoid overflowing */
    }
}