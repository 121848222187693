   /* ===== HOME Section START from here ===== */

   .Subscribe {
       box-sizing: border-box;
       text-decoration: none;
       moz-transition: all .4s ease-in-out;
       -o-transition: all .4s ease-in-out;
       -webkit-transition: all .4s ease-in-out;
       transition: all .4s ease-in-out;
       /* Set an appropriate height */
       margin: 0px !important;
       padding: 0px !important;
       min-width: 100% !important;
   }

   body {
       width: 100%;
       font-family: 'Poppins', sans-serif;
       color: #ffffff;
       background-color: #0A0825 !important;
       box-sizing: border-box;
   }

   * {
       box-sizing: border-box;
       text-decoration: none;
       moz-transition: all .4s ease-in-out;
       -o-transition: all .4s ease-in-out;
       -webkit-transition: all .4s ease-in-out;
       transition: all .4s ease-in-out;
   }

   #zoid-paypal-buttons-uid_13594c22bc_mdy6mji6ndu {
       position: fixed !important;
       display: fixed !important;
       width: 100%;
       min-height: 35px;
       min-width: 200px;
       font-size: 15px !important;
   }

   .hero-Payment-button {
       bottom: 0px;
       height: 150px;
       display: flex;
       align-items: center;
       justify-content: center;
       background-color: #0A0825;
       position: fixed;
       width: 100%;
       left: 50%;
       transform: translateX(-50%);
       bottom: 0px;
   }

   .hero-Payment-button p {
    color: green;
   }

   .hero-Payment-button button{
   font-weight: normal;
   color: #f1f1f1;
   min-width: 300px!important;
   text-align: center;
   justify-content: center;
   display: flex;
   background: blue!important;
   }

   .hero-Payment-button button a {
    margin: 0px 8px;
    font-weight: 700;
    color: #ffffff!important;
   }

   #paypal-button-container {
       width: 80%;
       max-width: 400px;
   }

   /* ===== Navbar Section START from here ===== */
   /*Navbar Start */

   .footer-nav {
       width: 100%;
       z-index: 10;
       position: relative;
       margin-right: auto;
       margin-left: auto;
       margin: 0px auto;
   }


   .navbar-brand {
       font-weight: bold;
   }

   .navbar-brand a {
       font-size: 21px;
       font-weight: 580;
       color: #f1f1f1;
       text-decoration: none;
       text-shadow: 4px 2px 20px 0px #201E2040;
   }

   .navbar-brand a span {
       transition: all 0.3s ease;
   }

   .navbar-nav {
       margin: 0px auto;
   }

   .nav-item {
       justify-content: space-around;
       color: #f1f1f1;
       margin: 0px 8px;
   }

   .nav-item a {
       font-size: 17px;
       font-weight: lighter;
       opacity: 0.7;
   }

   .nav-item i {
       font-size: 14px;
   }

   .navbar-toggler {
       border: transparent;
       color: transparent;
   }

   .bg-body-tertiary {
       background-color: transparent !important;
   }

   .cs-dark .cs-white_bg,
   .cs-dark .cs-nav .cs-nav_list ul {
       background-color: #283645;
   }

   .navbar {
       line-height: 2rem !important;
   }

   .navbar .navbar-brand {
       color: #FFF !important;
   }

   .navbar-nav .nav-item .nav-link {
       color: #ffffff;
   }

   .dropdown-menu.show {
       display: none;
   }

   .dropdown-toggle::after {
       display: none;
       margin: 0px;
   }

   .fa-angle-down {
       display: inline-block;
       position: relative;
       float: right;
       transform: rotate(0);
   }

   .nav-link[aria-expanded=true] .fa-angle-down {
       transform: rotate(180deg);
       transition: 0.25s all ease-in-out;
   }

   .nav-link {
       font-size: 21px;
       color: #ffffff;
   }

   .nav-link:hover {
       color: #ffffff;
   }

   /*navbar buttons*/

   .footer-buttons {
       text-transform: uppercase;
       align-items: center;
       display: flex;
       float: right;
   }

   .footer-buttons i {
       margin: 2px 12px;
       font-size: 16px;
       float: right;
   }

   #nav-icon {
       width: 35px;
       height: 35px;
       position: relative;
       margin: 0px auto !important;
       -webkit-transform: rotate(0deg);
       -moz-transform: rotate(0deg);
       -o-transform: rotate(0deg);
       transform: rotate(0deg);
       -webkit-transition: .5s ease-in-out;
       -moz-transition: .5s ease-in-out;
       -o-transition: .5s ease-in-out;
       transition: .5s ease-in-out;
       cursor: pointer;
       /*   border:1px solid red; */
   }

   #nav-icon span {
       display: block;
       position: absolute;
       height: 3px;
       width: 100%;
       background: #ffffff;
       border-radius: 9px;
       opacity: 1;
       right: 0;
       -webkit-transform: rotate(0deg);
       -moz-transform: rotate(0deg);
       -o-transform: rotate(0deg);
       transform: rotate(0deg);
       -webkit-transition: .25s ease-in-out;
       -moz-transition: .25s ease-in-out;
       -o-transition: .25s ease-in-out;
       transition: .25s ease-in-out;
       /*   border:4px solid black; */
   }

   #nav-icon span:nth-child(1) {
       top: 0px;
       -webkit-transform-origin: left center;
       -moz-transform-origin: left center;
       -o-transform-origin: left center;
       transform-origin: left center;
       width: 70%;
   }


   #nav-icon span:nth-child(2) {
       top: 10px;
       -webkit-transform-origin: left center;
       -moz-transform-origin: left center;
       -o-transform-origin: left center;
       transform-origin: left center;
   }

   #nav-icon span:nth-child(3) {
       top: 20px;
       -webkit-transform-origin: left center;
       -moz-transform-origin: left center;
       -o-transform-origin: left center;
       transform-origin: left center;
       width: 70%;
   }

   #nav-icon.open span:nth-child(1) {
       -webkit-transform: rotate(45deg);
       -moz-transform: rotate(45deg);
       -o-transform: rotate(45deg);
       transform: rotate(45deg);
       top: -3px;
       left: 2px;
       width: 100%;
   }

   #nav-icon.open span:nth-child(2) {
       width: 0%;
       opacity: 0;
   }

   #nav-icon.open span:nth-child(3) {
       -webkit-transform: rotate(-45deg);
       -moz-transform: rotate(-45deg);
       -o-transform: rotate(-45deg);
       transform: rotate(-45deg);
       top: 22px;
       left: 2px;
       width: 100%;
   }

   /* ===== Navbar Section END from here ===== */

   /* ===== BUTTONS Start from here ===== */

   .btn {
       text-align: center;
       display: flex;
       cursor: pointer;
       font-size: 14px;
       justify-content: center;
       align-items: center;
       text-transform: uppercase;
       font-weight: 600;
   }

   .btn-hover {
       font-size: 16px;
       font-weight: normal;
       color: #fff;
       padding: 15px 30px;
       cursor: pointer;
       text-align: center;
       border: none;
       background-size: 300% 100%;
       border-radius: 9px;
       moz-transition: all .4s ease-in-out;
       -o-transition: all .4s ease-in-out;
       -webkit-transition: all .4s ease-in-out;
       transition: all .4s ease-in-out;
   }


   .btn-hover:hover {
       background-position: 100% 0;
       moz-transition: all .4s ease-in-out;
       -o-transition: all .4s ease-in-out;
       -webkit-transition: all .4s ease-in-out;
       transition: all .4s ease-in-out;
   }

   .btn-hover:focus {
       outline: none;
   }

   .btn-hover.color-4 {
       background-image: linear-gradient(to right, #0ba360, #3cba92, #30dd8a, #2bb673);
       box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75);
   }

   .btn-hover.nav-btn {
       background-image: linear-gradient(to right, #f1f1f1, #f1f1f18b, #f1f1f1, #fff);
       border: 2px solid #0000002b;
       color: #f1f1f1d4;
       box-shadow: none;
   }

   /* ===== BUTTONS END from here ===== */

   .text-muted {
       --bs-text-opacity: 1;
       color: #ffffff !important;
   }

   .text-center {
       color: #ffffff !important;
   }

   .pricing .card {
       border: none;
       border-radius: 1rem;
       transition: all 0.2s;
   }

   .pricing hr {
       margin: 1.5rem 0;
   }

   .pricing .card-title {
       margin: 0.5rem 0;
       font-size: 0.9rem;
       letter-spacing: .1rem;
       font-weight: bold;
   }

   .pricing .card-price {
       font-size: 3rem;
       margin: 0;
   }

   .pricing .card-price .period {
       font-size: 0.8rem;
   }

   .pricing ul li {
       margin-bottom: 1rem;
   }

   .pricing .text-muted {
       opacity: 0.7;
   }

   .fa-ul {
       justify-content: space-between;
   }

   .card {
       color: #ffffff9b;
       background-color: transparent !important;
   }

   .hero-gen-button {
       height: 90px;
       display: flex;
       align-items: center;
       justify-content: center;
       background-color: #0A0825;
       position: fixed;
       width: 100%;
       left: 50%;
       transform: translateX(-50%);
       bottom: 0px;
   }

   /* Hover Effects on Card */

   @media (min-width: 992px) {

       .card {
           color: #ffffff9b;
           background-color: transparent !important;
       }

   }

   #more {
       display: none;
   }