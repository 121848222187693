 .otherPage {
     box-sizing: border-box;
     text-decoration: none;
     moz-transition: all .4s ease-in-out;
     -o-transition: all .4s ease-in-out;
     -webkit-transition: all .4s ease-in-out;
     transition: all .4s ease-in-out;
     /* Set an appropriate height */
     margin: 0px !important;
     padding: 0px !important;
 }

 body {
     font-family: 'Poppins', sans-serif;
     color: #ffffff;
     background-color: #0A0825 !important;
     box-sizing: border-box;
 }

 .loading-indicator {
     display: flex;
     align-items: center;
     justify-content: center;
     height: 100vh;
     /* Adjust the height as needed */
     font-size: 24px;
     font-weight: bold;
     background-color: #080526;
     /* Semi-transparent background */
     color: white;
     position: fixed;
     top: 0;
     left: 0;
     width: 100%;
     z-index: 1;
 }


 .loading-indicator s{
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    color: #0ba565;
    backdrop-filter: blur(12px);
    padding:  13px 0px;
    border-radius: 8px;
 }


.loading-indicator button {
    margin: 0 auto;
    /* Horizontal centering */
}


 /* -------------- Clock -------------- */

 .clock {
     border-radius: 60px;
    border: 3px solid #ffffff2b;
     height: 80px;
     width: 80px;
     position: relative;
     top: 38%;
     top: -webkit-calc(50% - 43px);
     top: calc(50% - 43px);
     left: 35%;
     left: -webkit-calc(50% - 43px);
     left: calc(50% - 43px);
    
 }

 .clock:after {
     content: "";
     position: absolute;
    background-size: 300% 100%;
        border-radius: 9px;
        background-image: linear-gradient(to right, #0ba360, #3cba92, #30dd8a, #2bb673);
      
     top: 2px;
     left: 48%;
     height: 38px;
     width: 4px;
     border-radius: 5px;
     -webkit-transform-origin: 50% 97%;
     transform-origin: 50% 97%;
     -webkit-animation: grdAiguille 2s linear infinite;
     animation: grdAiguille 2s linear infinite;
 }

 @-webkit-keyframes grdAiguille {
     0% {
         -webkit-transform: rotate(0deg);
     }

     100% {
         -webkit-transform: rotate(360deg);
     }
 }

 @keyframes grdAiguille {
     0% {
         transform: rotate(0deg);
     }

     100% {
         transform: rotate(360deg);
     }
 }

 .clock:before {
     content: "";
     position: absolute;
    background-size: 300% 100%;
        border-radius: 9px;
        background-image: linear-gradient(to right, #0ba360, #3cba92, #30dd8a, #2bb673);
     top: 6px;
     left: 48%;
     height: 35px;
     width: 4px;
     border-radius: 5px;
     -webkit-transform-origin: 50% 94%;
     transform-origin: 50% 94%;
     -webkit-animation: ptAiguille 12s linear infinite;
     animation: ptAiguille 12s linear infinite;
 }

 @-webkit-keyframes ptAiguille {
     0% {
         -webkit-transform: rotate(0deg);
     }

     100% {
         -webkit-transform: rotate(360deg);
     }
 }

 @keyframes ptAiguille {
     0% {
         transform: rotate(0deg);
     }

     100% {
         transform: rotate(360deg);
     }
 }

 

 .back-btn {
     position: absolute;
     top: 0px;
     left: 0px;
     color: #EEEDED;
     font-size: 18px;
     backdrop-filter: none!important;
 }

 .back-btn button {
    background-color: #0A08254b!important;
    backdrop-filter: none !important;
    margin: 0px 10px;
    border-radius: 50%;
 }

 .hero-gen-btn {
     position: absolute;
     z-index: 1;
     top: 0px;
     right: 0px;
 }


 .hero-gen-btn button {
     margin: 0px 3px;
     color: #EEEDED;
     background: #ffffff1d !important;
     border-radius: 50%;
 }

 .remove-blur-btn {
     position: absolute;
     text-align: center;
     margin: 0;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     display: flex;
     align-items: center;
     justify-content: center;
     padding: 5px;
     font-weight: bold;
     cursor: pointer;
     border: none;
     background-size: 300% 100%;
     border-radius: 30px !important;
     background-image: linear-gradient(to right, #0ba360, #3cba92, #30dd8a, #2bb673);
     moz-transition: all .4s ease-in-out;
     -o-transition: all .4s ease-in-out;
     -webkit-transition: all .4s ease-in-out;
     transition: all .4s ease-in-out;
     background-position: 100% 0;
 }

 .remove-blur-btn button {
     backdrop-filter: blur(0px);
 }


 .remove-blur-btn:hover {
     background-position: 100% 0;
     moz-transition: all .4s ease-in-out;
     -o-transition: all .4s ease-in-out;
     -webkit-transition: all .4s ease-in-out;
     transition: all .4s ease-in-out;
 }

 .remove-blur-btn:focus {
     outline: none;
 }

 .Try-again-btn {
     position: fixed;
     text-align: center;
     margin: 0;
     bottom: 0%;
     cursor: pointer;
     left: 50%;
     transform: translate(-50%, -50%);
     width: 70%;
     background: #ffffff2b;
     backdrop-filter: blur(8px);
     border-radius: 18px;
     padding: 10px 20px;
 }

 .FaRedoAlt {
     margin: 0px 5px;
 }

 .color-1 {
     padding: 16px;
     text-align: center;
     position: fixed;
     width: 66%;
     display: flex;
     align-items: center;
     justify-content: center;
     cursor: pointer;

 }