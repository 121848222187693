/* PrivacyPolicy.css */

.privacy-policy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f1f1f106;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.privacy-policy-title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
}

.privacy-policy-content {
    font-size: 16px;
    line-height: 1.6;
}

.privacy-policy-content p {
    color: #f1f1f19f;
    font-weight: 200;
}

.privacy-policy-content li {
    color: #f1f1f19f;
    font-weight: 200;
}

.privacy-policy-content h2 {
    color: #f1f1f19f;
}

/* Add responsive styles */
@media (max-width: 768px) {
    .privacy-policy-container {
        padding: 10px;
    }
}

.display-4 {
    margin: 10px auto;
}

.privacy-policy-paragraph {
    border-bottom: 1px solid #f1f1f11f;
    margin-bottom: 22px;
    padding-bottom: 10px;
}

.span {
    color: #f1f1f1 !important;
    padding: 2px 0!important;
}